export function iRevSetParams(url) {
    if (url.includes('hash=')) {
        try {
            const urlParams = new URLSearchParams(window.location.search);
            let params = Object.fromEntries(urlParams);
            
            let cookie = "params" + "=" + encodeURIComponent(JSON.stringify(params));
            let daysToLive = 30;
    
            cookie += "; max-age=" + (daysToLive * 24 * 60 * 60);
            document.cookie = cookie + ";path=/";
        } catch (error) {
            console.log(`Error, IRev integration, creating cookie, ${error}`)
        }
    }
}

export function iRevGetParams() {
    let cookieArr = document.cookie.split(";");

    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if ('params' == cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1].trim());
        }
    }

    return '';
}


export async function sendRegistrationIRev(user) {
    /********************/
    /** Getting available user params */
    let params;

    try {
        params = JSON.parse(user?.params);
    } catch (error) {
        console.log(`Error, sendRegistrationIRev, Could not parse params, ${user?.email}, ${error}`);
        return;
    }

    /********************/
    /** Not part of the iRev traffic */
    if (!params?.hash) {
        console.log(`Note, sendRegistrationIRev, ${user?.email} has no hash`);
        return;
    }

    /********************/
    /** Prepare all data */
    let data = {
        "hash": params?.hash,
        "affiliate_click": params?.aff_click,
        "affiliate_source": params?.aff_source,
        "deposit_amount": 0,
        "sale_amount": 0,
        "revenue": 0,
    };

    /********************/
    /** Adding other params */
    params?.aff_sub && (data.aff_sub = params?.aff_sub)
    params?.aff_sub2 && (data.aff_sub2 = params?.aff_sub2)
    params?.aff_sub3 && (data.aff_sub3 = params?.aff_sub3)
    params?.aff_sub4 && (data.aff_sub4 = params?.aff_sub4)
    params?.aff_sub5 && (data.aff_sub5 = params?.aff_sub5)

    let searchParams = new URLSearchParams(data).toString();

    /********************/
    /** Send conversion */
    try {
        const request = await fetch(`https://spingold.irev.com/backend/api/v3/goal-type-fire/1?${searchParams}`);
        const response = await request.json();
        console.log(`sendRegistrationIRev, success for ${user?.email}`);
        console.log(response);
    } catch (error) {
        console.log(`Error, sendRegistrationIRev, Could not send conversion for ${user?.email}, ${error}`);
    }
}