import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

export default function ChoosePaymentMethodCorefy({ setPaymentMethod, transitionToStep, isQuickDeposit }) {
    const user = useSelector((state) => state.user.data)

    useEffect(() => {
        !isQuickDeposit && setTimeout(() => { window.scrollTo(0, 0); }, 0);
    }, [])

    return (
        <>
            {
                {
                    'CAD': <PaymentMethodsCAD {...{setPaymentMethod, transitionToStep}} />,
                    'USD': <PaymentMethodsUSD {...{setPaymentMethod, transitionToStep}} />,
                    'EUR': <PaymentMethodsEUR {...{setPaymentMethod, transitionToStep}} />,
                    'NZD': <PaymentMethodsNZD {...{setPaymentMethod, transitionToStep}} />,
                }[user?.currency?.code]
            }
        </>
    )
}

function PaymentMethodsCAD({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            <Button onClick={() => { setPaymentMethod('interac_aggregated_cad_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment interac">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/interac-color-logo-4.png" alt="Interac" />
                    <span>Interac®</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Button onClick={() => { setPaymentMethod('coinspaid'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/coinspaid-logo.png" alt="Coinspaid" />
                    <span>Coinspaid (Crypto)</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}

            <Button onClick={() => { setPaymentMethod('payment_card_cad_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa / Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Button onClick={() => { setPaymentMethod('much_better'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment muchbetter" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/muchbetter-logo.png" alt="MuchBetter" />
                    MuchBetter
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}
        </>
    )
}

function PaymentMethodsUSD({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            {/* <Button onClick={() => { setPaymentMethod('coinspaid'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/coinspaid-logo.png" alt="Coinspaid" />
                    <span>Coinspaid (Crypto)</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}

            <Button onClick={() => { setPaymentMethod('payment_card_usd_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa/Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Button onClick={() => { setPaymentMethod('much_better'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment muchbetter" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/muchbetter-logo.png" alt="MuchBetter" />
                    MuchBetter
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}
        </>
    )
}

function PaymentMethodsEUR({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            {/* <Button onClick={() => { setPaymentMethod('interac_aggregated_cad_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/coinspaid-logo.png" alt="Coinspaid" />
                    <span>Coinspaid (Crypto)</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}

            <Button onClick={() => { setPaymentMethod('payment_card_eur_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa/Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Button onClick={() => { setPaymentMethod('much_better'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment muchbetter" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/muchbetter-logo.png" alt="MuchBetter" />
                    MuchBetter
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}
        </>
    )
}

function PaymentMethodsNZD({ setPaymentMethod, transitionToStep }) {
    return (
        <>
            {/* <Button onClick={() => { setPaymentMethod('coinspaid'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment coinspaid" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/coinspaid-logo.png" alt="Coinspaid" />
                    <span>Coinspaid (Crypto)</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}

            <Button onClick={() => { setPaymentMethod('payment_card_nzd_hpp'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment visa">
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/visa-logo-3.png" alt="Visa" />
                    <span>Visa/Mastercard</span>
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button>

            {/* <Button onClick={() => { setPaymentMethod('much_better'); transitionToStep(3) }}
                className="btn-secondary btn-choose-payment muchbetter" >
                <div>
                    <img src="https://cherryspins.b-cdn.net/images/muchbetter-logo.png" alt="MuchBetter" />
                    MuchBetter
                </div>
                <FontAwesomeIcon icon={faChevronRight} />
            </Button> */}
        </>
    )
}